import React, { useState, useEffect } from 'react';
import { useTower } from '../contexts/TowerContext';

const TowerSelector = () => {
  const { selectedTower, setSelectedTower } = useTower();
  const [towers, setTowers] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  // Function to fetch towers
  const handleFetchTowers = async () => {
    if (!isFetched) {
      try {
        const apiUrl = process.env.REACT_APP_FORECAST_API_URL;
        const response = await fetch(`${apiUrl}/towers`);
        const data = await response.json();
        const targetNames = ["BE06", "BE42"];
        const filteredData = data.filter(tower => targetNames.includes(tower.name));
        setTowers(filteredData);
        setIsFetched(true); // Indicate that data has been fetched
      } catch (error) {
        console.error('Failed to fetch towers:', error);
      }
    }
  };

  // Fetch towers when the component mounts
  useEffect(() => {
    handleFetchTowers();
    // Since handleFetchTowers updates state, it should not be directly included as a dependency
    // to avoid unnecessary re-fetches. Instead, its dependencies should be tracked manually
    // if needed. In this case, since handleFetchTowers checks if fetching is necessary,
    // it's safe to omit dependencies.
  }, []); // Empty dependency array ensures this effect runs only once after mount

  return (
    <div>
      Selected Tower: <select
        value={selectedTower?.name}
        onChange={e => {
          const tower = towers.find(t => t.name === e.target.value);
          setSelectedTower(tower);
        }}
        onFocus={handleFetchTowers} // Also fetch towers when focused, if not already fetched
      >
        {towers.map(tower => (
          <option key={tower.name} value={tower.name}>
            {tower.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TowerSelector;
